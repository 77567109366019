.navbar {
    width: 100%;
    background-color: var(--brand-color-white);
    display: flex;
    justify-content: space-between;
    align-items: center;

    top: 0;
    position: fixed;
    transition: top 0.3s;
    border-bottom: 2px solid #dee0e3;
    z-index: 10;
}

.navbar-image {
    margin: 10px;
    height: 55px;
    border-radius: 10px;
    display: block;
}

.navlist {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navlist li {
    margin-left: 10px;
    margin-right: 10px;
}

.navlist li a {
    text-decoration: none;
    padding: 15px;
    color: #202124;
    display: inline-block;
    text-align: center;
}

.navlist li a::after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

.navlist li a:visited {
    color: #202124;
}

.navlist li a:hover {
    color: #0a19b6;
    font-weight: bold;
}

.navlist li a:active {
    color: #0a19b6;
}

.navTitle, .navTitle:hover, .navTitle:active, .navTitle:visited {
    padding-left: 20px;
    color: #202124;
    display: block;
    margin: 0;
}

.navLeft {
    display: flex;
    align-items: center;
}

.pageActive {
    color: #0a19b6 !important;
    font-weight: bold;
}

.openNav {
    display: none;
    font-size: 35px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.mobileNavlist {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #12263e;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeDiv {
    width: 100%;
    display: flex;
    justify-content: end;
}

.navClose {
    font-size: 35px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    color: #fff;
}

.mobileLinks {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
}

.mobileLink {
    display: flex;
    box-sizing: border-box;
    margin: 5px;
    padding: 5px;
    color: #fff;
    font-size: 20px;
}

.mobileLink:hover {
    color: #ccc;
}

.mobileLink:active, .mobileLink:visited {
    color: #fff;
}

@media screen and (max-width: 720px) {
    .navlist {
        display: none;
    }
    .openNav {
        display: block;
    }
}