.skillDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 2px #dee0e3;
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
}

.imageDiv img {
    width: 100%;
    max-width: 150px;
}

.skillDiv h2 {
    text-align: center;
    margin-bottom: 0;
}

.imageDiv {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}