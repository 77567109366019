.contactDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactForm {
    width: 70%;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formElement {
    width: 100%;
    margin-top: 7px;
}

.sendError {
    color: #ff3333;
}

.sendMsg {
    font-size: 25px;
}

@media screen and (max-width: 720px) {
    .contactForm {
        width: 95%;
    }

    .submitBtn {
        width: 100%;
    }
}