.footer {
    padding: 30px;
    padding-bottom: 0;
    background-color: #12263e;
    color: var(--brand-color-white);
    margin: 0;
    margin-top: 20px;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 15px;
}

.footer-bottom p {
    margin: 0;
}

.footLinks, .footLinks:visited, .footLinks:active {
    color: var(--brand-color-white);
}

.footLinks:hover {
    color: #ccc;
}

.footerSocialLinks {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.footerSocialLinks a {
    margin: 0 10px 0 10px;
    color: #fff;
}

.footerSocialLinks a:hover {
    color: #ccc;
}

.footerSocialLinks a i {
    font-size: 50px;
}

.footerConnect {
    text-align: center;
    margin: 0;
}