:root {
    --brand-color-blue: #0a19b6;
    --brand-color-white: #ffffff;
}

body {
    margin: 0;
    font-family: 'Jost', sans-serif;
    color: #202124;
}

.main {
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
}

/* Base Links */
a {
    text-decoration: none;
    color: #3f77f5;
    transition: 0.1s;
}

a:hover {
    color: #0a41c2;
}

a:active {
    color: #3f77f5;
}

.pageTitle {
    text-align: center;
    font-size: 42px;
    margin-bottom: 0;
}

.innerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}