.homeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introText {
    text-align: center;
    font-size: 20px;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}