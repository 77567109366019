:root {
    --brand-color-1: #999;
    --brand-color-2: #0a19b6;
    --brand-color-3: #fff;
}

:disabled {
    cursor: not-allowed;
}

.input {
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    position: relative;
}

.input button, input[type=button] {
    padding: 10px 14px 10px 14px;
    background-color: var(--brand-color-2);
    color: white;
    border: 2px var(--brand-color-2) solid;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: .2s;
    outline: 0;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    width: 100%;
}

.input button:hover, input[type=button]:hover {
    background-color: var(--brand-color-3);
    color: var(--brand-color-2);
}

.input button:active, input[type=button]:active {
    transition: 0s;
    background-color: var(--brand-color-3);
    border-color: var(--brand-color-3);
    color: var(--brand-color-2);
}

.input button:disabled, input[type=button]:disabled {
    background-color: #545454;
    border-color: #545454;
    color: var(--brand-color-3);
    cursor: not-allowed;
}

.check-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-top: 10px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.check-label input[type=checkbox] {
    cursor: pointer;
    position: absolute;
    display: none;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.check-container {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #00000000;
    border: 3px solid var(--brand-color-2);
    border-radius: 2px;
    transition: .2s;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.check-label input[type=checkbox]:checked ~ .check-container {
    background-color: var(--brand-color-2);
    transition: .2s;
}

.check-container::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 6px;
    height: 12px;
    border-color: var(--brand-color-3);
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.check-label input[type=checkbox]:checked ~ .check-container::after {
    display: block;
}

.check-label input[type=checkbox]:checked:disabled ~ .check-container {
    background-color: #545454;
}

.check-label input[type=checkbox]:disabled ~ .check-container {
    border-color: #545454;
    cursor: not-allowed;
}

.check-label input[type=checkbox]:disabled ~ .check-text {
    cursor: not-allowed;
}

/* .step input[type=text],input[type=password],input[type=date],select {
    width: 100%;
    border: 0;
    background-color: #00000000;
    padding: 10px;
    padding-bottom: 2px;
    font-size: 16px;
} */

.input input[type=text],input[type=password],input[type=date],select {
    margin-top: 0;
    margin-bottom: 1px;
    border: 0;
    background-color: #00000000;
    padding: 10px;
    padding-bottom: 2px;
    padding-top: 21px;
    border-bottom: 2px solid #999;
    transition: border-color 0.2s ease-out;
    font-size: 20px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}

.input input[type=text]:focus,input[type=password]:focus,input[type=date]:focus,select:focus {
    border-bottom: solid 2px var(--brand-color-2);
    outline: 0;
}

.input input[type=text]:disabled,input[type=password]:disabled,input[type=date]:disabled,select:disabled {
    background-color: rgba(0,0,0,0.1);
    color: #545454;
}




.input .floating-label,
.input input:focus ~ .floating-label {
    position: absolute;
    pointer-events: none;
    top: 21.5px;
    left: 10px;
    transition: 0.2s ease all;
    font-size: 20px;
    color: #757575;
}

.input input:focus ~ .floating-label,
.input input:not(:placeholder-shown) ~ .floating-label {
    top: 0;
    left: 10px;
    font-size: 14px;
    opacity: 1;
    color: var(--brand-color-2);
}

.input input:not(:placeholder-shown):not(:focus) ~ .floating-label {
    color: #757575;
}

.input textarea {
    margin-top: 21px;
    margin-bottom: 1px;
    background-color: #00000000;
    border: 2px solid #999;
    transition: border .2s ease-out;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    outline: 0;
    border-radius: 5px;
    font-family: 'Jost', sans-serif;
    color: #202124;
    padding: 7px;
}

.input .floating-label-ta,
.input textarea:focus ~ .floating-label-ta {
    position: absolute;
    pointer-events: none;
    top: 29.5px;
    left: 8.5px;
    transition: 0.2s ease all;
    font-size: 18px;
    color: #757575;
}

.input textarea:focus ~ .floating-label-ta,
.input textarea:not(:placeholder-shown) ~ .floating-label-ta {
    top: 0;
    left: 10px;
    font-size: 14px;
    opacity: 1;
    color: var(--brand-color-2);
}

.input textarea:not(:placeholder-shown):not(:focus) ~ .floating-label-ta {
    color: #757575;
}

.input textarea:focus {
    border: solid 2px var(--brand-color-2);
}

.input textarea:disabled {
    background-color: rgba(0,0,0,0.1);
    color: #545454;
}

.input .inputError {
    color: #ff3333;
    font-size: 15px;
}