.aboutDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profilePic {
    max-width: 300px;
    height: 100%;
    margin: 0 10px 0 10px;
}

.sideBySide {
    display: flex;
    width: 70%;
}

.textDiv{
    margin: 0 10px 0 10px;
}

.socialLinks a {
    margin: 0 10px 0 10px;
    color: #202124;
}

.socialLinks a:hover {
    color: #0a19b6;
}

.socialLinks a i {
    font-size: 50px;
}

@media screen and (max-width: 720px) {
    .sideBySide {
        flex-direction: column;
        align-items: center;
        width: 95%;
    }

    .profilePic {
        height: auto;
    }
}